import React from 'react';
import styled from 'styled-components';
import ScreenReaderText from 'components/screen-reader-text';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/pro-solid-svg-icons/faCircle';

const Pulse = styled.svg`
	circle {
		stroke: ${p => p.theme.colors.green600};
		&:last-of-type {
			fill: ${p => p.theme.colors.green600};
		}
	}
`;

export default function Status({ open = false, text }) {
	if (open)
		return (
			<>
				<Pulse
					width="30"
					height="30"
					viewbox="0 0 30 30"
					xmlns="http://www.w3.org/2000/svg"
					color="green">
					<circle cx="15" cy="15" fill="none" r="5" strokeWidth="2">
						<animate
							attributeName="r"
							from="5"
							to="15"
							dur="1.5s"
							begin="0s"
							repeatCount="indefinite"
						/>
						<animate
							attributeName="opacity"
							from="1"
							to="0"
							dur="1.5s"
							begin="0s"
							repeatCount="indefinite"
						/>
					</circle>
					<circle cx="15" cy="15" r="6" />
				</Pulse>
				<ScreenReaderText>{text}</ScreenReaderText>
			</>
		);

	return (
		<>
			<FontAwesomeIcon
				icon={faCircle}
				size="lg"
				style={{ color: '#df7b7b', fontSize: '18px' }}
			/>

			<ScreenReaderText>{text}</ScreenReaderText>
		</>
	);
}
