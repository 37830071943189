import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useApolloClient } from '@apollo/client';

import Chat from './Chat';
import Loading from './loading';
import Link from 'components/Link';
import useSalesforceChat, { getUser, buttonId } from './useSalesforceChat';
import Button from 'components/forms/Button';

//#region
const ChatClosed = styled.p`
	margin: 0;
	flex-grow: 1;
`;
//#endregion

export default function SalesforceCard({ title }) {
	const client = useApolloClient();
	const {
		agentAvailable,
		loadingAvailability,
		openingEmbed,
		setOpeningEmbed,
		embedOpen,
		setEmbedOpen,
		triggerChatOpen,
		isSalesforceLoaded,
	} = useSalesforceChat();

	const chatClosed = false;

	// Trigger chat open with prechat fields if user has contact info otherwise trigger chat open without prechat fields
	async function triggerEmbedOpen() {
		setOpeningEmbed(true);

		if (!window.embedded_svc) return;

		const user = await getUser(client);

		const hasContactInfo = user?.email || user?.phone;

		triggerChatOpen(!hasContactInfo, {
			directToAgentRouting: {
				buttonId,
				userId: user?.external_id,
				fallback: true,
				prepopulatedPrechatFields: {
					FirstName: user?.first_name,
					LastName: user?.last_name,
					SuppliedEmail: user?.email,
					SuppliedPhone: user?.phone,
					Subject: '',
				},
			},
		});

		setTimeout(() => {
			setOpeningEmbed(false);
		}, 1000);

		setEmbedOpen(true);
	}

	useEffect(() => {
		if (!isSalesforceLoaded) {
			console.error('Salesforce not loaded, cannot open chat');
			return;
		}

		if (!agentAvailable) {
			console.log('No agents available, cannot open chat');
			return;
		}

		const urlParams = new URLSearchParams(window.location.search);
		const chat = urlParams.get('chat');

		if (chat === 'salesforce' && !embedOpen) {
			triggerEmbedOpen();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSalesforceLoaded, agentAvailable]);

	if (chatClosed) {
		return (
			<Chat
				title={title}
				open={false}
				statusText="Ingen kundebehandlere er tilgjengelige for chat">
				<ChatClosed>
					Ingen kundebehandlere tilgjengelig for chat. <br />
					Kontakt oss gjerne via vårt{' '}
					<Link
						to="/kundeservice/kontaktskjema/strom/"
						title="Kontakt kundeservice via kontaktskjema">
						kontaktskjema
					</Link>
				</ChatClosed>
			</Chat>
		);
	}

	return (
		<>
			<script
				type="text/javascript"
				src="https://service.force.com/embeddedservice/5.0/esw.min.js"
				defer
			/>
			<Chat
				title={title}
				open={agentAvailable}
				statusText={
					agentAvailable
						? 'Kundebehandler er tilgjengelig for chat'
						: 'Ingen kundebehandlere er tilgjengelige for chat'
				}>
				{loadingAvailability || !isSalesforceLoaded ? (
					<Loading />
				) : (
					<>
						{agentAvailable ? (
							<>
								<Button
									title={`Start Chat med ${title}`}
									disabled={embedOpen || openingEmbed}
									onClick={async () => {
										await triggerEmbedOpen();
									}}>
									{(openingEmbed && 'Starter Chat') ||
										(embedOpen && 'Chat startet') ||
										'Start Chat'}
								</Button>
							</>
						) : (
							<ChatClosed>
								Ingen kundebehandlere tilgjengelig for chat.{' '}
								<br />
								Kontakt oss gjerne via vårt{' '}
								<Link
									to="/kundeservice/kontaktskjema/strom/"
									title="Kontakt kundeservice via kontaktskjema">
									kontaktskjema
								</Link>
							</ChatClosed>
						)}
					</>
				)}
			</Chat>
		</>
	);
}
