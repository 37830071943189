import { useEffect, useState } from 'react';

import { removeSpecialCharacters, scrollToElement } from 'libs/content';
import { handleOpenTab } from '../../parts/accordion/AccordionGroup';

/**
 * Custom hook to handle scrolling behavior based on URL parameters and open tabs.
 *
 * @param {Object} options - The options object.
 * @param {Object} options.props - The props object containing necessary data.
 * @param {Array} options.openTabs - The array of currently open top-level tabs.
 * @param {Function} options.setOpenTabs - The function to update the open top-level tabs.
 * @param {Array} options.openChildTabs - The array of currently open child tabs.
 * @param {Function} options.setOpenChildTabs - The function to update the open child tabs.
 * @param {Function} options.setShowAllTabs - The function to show all tabs when necessary.
 * @param {number} options.tabLimit - The limit of top-level tabs to be displayed (default is 5).
 */
export const useScrollToTabs = ({
	props,
	openTabs,
	setOpenTabs,
	openChildTabs,
	setOpenChildTabs,
	setShowAllTabs,
	tabLimit = 5,
}) => {
	const [hasScrolled, setHasScrolled] = useState(false);
	const scrollTo = props?.scrollTo;

	function scrollToTab(onlyOnce = false) {
		if (!scrollTo) return;

		// Don't proceed if is set to scroll only once and has already scrolled
		if (onlyOnce && hasScrolled) return;

		// Scroll to group element after 500ms if no accordion or child-accordion
		if (!scrollTo?.accordion && !scrollTo?.childAccordion) {
			setTimeout(() => {
				scrollToElement(document?.getElementById(scrollTo?.group));
			}, 500);
		}

		// If the tab's index position in the array is greater than the tab-limit then open list of all tabs
		const tabIndex = props?.accordions.findIndex(
			tab => tab?.tabId === scrollTo?.accordion
		);
		if (tabIndex + 1 > tabLimit) {
			setShowAllTabs(true);
		}

		// Find the tab based on the accordion from url-param
		const tab = props?.accordions?.find(
			t => t?.tabId === scrollTo?.accordion
		);

		// If top-level-accordion exists as element with matching ID then open it
		setTimeout(() => {
			if (!document.getElementById(tab?.tabId)) return;

			// Open current tab
			handleOpenTab({
				id: tab?.tabId,
				open: openTabs,
				setOpen: setOpenTabs,
				context: 'Page load',
				group: scrollTo?.group,
				accordion: scrollTo?.accordion || scrollTo?.childAccordion,
			});

			// If has no child-accordion in URL-parameter then scroll to element
			if (!scrollTo?.childAccordion) {
				// Scroll to current tab
				scrollToElement(document.getElementById(tab?.tabId));

				// If is set to only scroll once then update state
				if (onlyOnce) setHasScrolled(true);
				return;
			}
		}, 1000);

		setTimeout(() => {
			// If no tab exists or no child-accordion set as url-param then don't proceed
			if (
				!document.getElementById(tab?.tabId) ||
				!scrollTo?.childAccordion
			)
				return;

			// Find the child-tab based on the childAccordion from url-param
			const childTab = tab?.faqs?.find(
				faq =>
					scrollTo?.childAccordion ===
					`${scrollTo?.accordion}-${removeSpecialCharacters(
						faq?.title
					)}`
			);

			// If no child-tab found then don't proceed
			if (!childTab) return;

			// Define the id based on parent-accordion and child's title
			const element = `${scrollTo?.accordion}-${removeSpecialCharacters(
				childTab?.title
			)}`;

			// If no element with that id found then don't proceed
			if (!document?.getElementById(element)) return;

			// Open current tab
			handleOpenTab({
				id: element,
				open: openChildTabs,
				setOpen: setOpenChildTabs,
				context: 'Page load',
				group: scrollTo?.group,
				accordion: scrollTo?.childAccordion,
			});

			// Scroll to current tab
			scrollToElement(document?.getElementById(element));

			// If is set to only scroll once then update state
			if (onlyOnce) setHasScrolled(true);
		}, 1500);
	}

	// Run function when any open tabs and accordion as url-param
	useEffect(() => {
		scrollToTab();
		//eslint-disable-next-line
	}, [openTabs?.length, scrollTo?.accordion, scrollTo?.childAccordion]);

	// If has "group" as URL-parameter
	useEffect(() => {
		if (!props?.groupChosen) return;
		scrollToTab();
		//eslint-disable-next-line
	}, [props?.groupChosen]);
};
