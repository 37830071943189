import React from 'react';
import styled, { css } from 'styled-components';

import { removeSpecialCharacters } from 'libs/content';
import BlankButton from 'components/forms/BlankButton';

const Options = styled.ul`
	list-style: none;
	margin: 0;
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	li {
		${p =>
			p.theme.media.smallOnly(css`
				width: calc(20% - 5px);
			`)}
	}
`;

const ScoreBtn = styled(BlankButton)`
	font-size: 28px;
	font-weight: 600;
	padding: 35px 50px;
	border-radius: ${p => p.theme.utils.borderRadius};
	background-color: ${p => p.theme.colors.blue200};
	text-decoration: none;
	color: ${p => p.theme.colors.blue600};
	justify-content: center;
	border: 2px solid transparent;

	${p =>
		p.theme.media.smallOnly(css`
			font-size: 22px;
			padding: 15px 5px;
			width: 100%;
		`)}
	${p =>
		(p.active &&
			css`
				background-color: ${p => p.theme.colors.blue600};
				color: ${p => p.theme.colors.white};
				&:hover,
				&:focus {
					background-color: ${p => p.theme.colors.blue600} !important;
					border: 2px solid transparent !important;
					color: ${p => p.theme.colors.white} !important;
				}
			`) ||
		css`
			&:hover,
			&:focus {
				background-color: ${p => p.theme.colors.blue300};
				border: 2px solid ${p => p.theme.colors.blue400};
			}
		`}
`;

const Description = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 20px;
	${p =>
		p.theme.media.smallOnly(css`
			margin-top: 10px;
			font-size: 15px;
		`)}
`;

export default function Rate({ currentQuestion, currentAnswer, saveRating }) {
	const fieldId = removeSpecialCharacters(currentQuestion?.title);

	return (
		<div id={fieldId} name={fieldId}>
			<Options>
				{Array.from({ length: 5 }, (_, rate) => {
					rate++;
					return (
						<li key={rate}>
							<ScoreBtn
								onClick={e => saveRating(e, rate)}
								title={`Velg ${rate}`}
								active={currentAnswer?.answer === rate}
								text={rate}>
								{rate}
							</ScoreBtn>
						</li>
					);
				})}
			</Options>

			<Description>
				<div>Ikke fornøyd</div>
				<div>Fornøyd</div>
				<div>Veldig fornøyd</div>
			</Description>
		</div>
	);
}
