import { gql, useApolloClient } from '@apollo/client';
import useTimer from 'context/useTimer';
import { useEffect, useRef, useState } from 'react';

export const GET_CHAT_AVAILABILITY_STATUS = gql`
	query getChatAvailabiltyStatus(
		$baseLiveAgentURL: String
		$orgId: String
		$deploymentId: String
		$availabilityIds: [String]!
	) {
		getChatAvailabiltyStatus(
			baseLiveAgentURL: $baseLiveAgentURL
			orgId: $orgId
			deploymentId: $deploymentId
			availabilityIds: $availabilityIds
		) {
			isAvailable
			estimatedWaitTime
		}
	}
`;

export const TRACK_CHAT_EVENT = gql`
	mutation trackChatEvent($event: String!, $properties: JSON) {
		trackChatEvent(event: $event, properties: $properties)
	}
`;

export const GET_INTERCOM_CONTACT = gql`
	query getIntercomContact($email: String, $phone: String) {
		getIntercomContact(email: $email, phone: $phone) {
			external_id
			first_name
			last_name
			name
			email
			phone
		}
	}
`;

export async function getUser(client) {
	const params = Object.fromEntries(
		new URLSearchParams(window.location.search)
	);

	const { id, ID, first_name, last_name, email, phone, subject } = params;

	// If subject is 'mittnte' return the user data as is
	if (subject === 'mittnte') {
		return {
			id,
			ID,
			first_name,
			last_name,
			email,
			phone,
			subject,
		};
	}

	// If no email or phone return
	if (!email && !phone) return;

	// Get user data from Intercom
	return await client
		.query({
			query: GET_INTERCOM_CONTACT,
			variables: {
				variables: { email, phone },
			},
		})
		.catch(error => {
			console.error(error);
			return false;
		})
		.then(({ data }) => {
			const userData = {
				id: id || ID || data?.getIntercomContact?.id,
				first_name: first_name || data?.getIntercomContact?.first_name,
				last_name: last_name || data?.getIntercomContact?.last_name,
				email: email || data?.getIntercomContact?.email,
				phone: phone || data?.getIntercomContact?.phone,
				subject,
			};
			return userData;
		});
}

let salesforceScript =
	'https://nte--test.sandbox.my.salesforce.com/embeddedservice/5.0/esw.min.js';
let initUrl = 'https://nte--test.sandbox.my.salesforce.com';
let initUrlSetupFlow =
	'https://nte--test.sandbox.my.salesforce-sites.com/liveAgentSetupFlow';
let baseLiveAgentContentURL =
	'https://d.la12s-core1.sfdc-cehfhs.salesforceliveagent.com/content';
let baseLiveAgentURL =
	'https://d.la12s-core1.sfdc-cehfhs.salesforceliveagent.com/chat';
let orgId = '00DJX000007JO7R';
let eswLiveAgentDevName = 'Chat_Support';
let deploymentId = '57208000000L0Do';
export let buttonId = '57308000000L0LE';

// Production environment variables
if (process.env.GATSBY_URL === 'https://nte.no') {
	salesforceScript =
		'https://nte.my.salesforce.com/embeddedservice/5.0/esw.min.js';
	initUrl = 'https://nte.my.salesforce.com';
	initUrlSetupFlow = 'https://nte.my.salesforce-sites.com/liveAgentSetupFlow';
	baseLiveAgentContentURL =
		'https://d.la11-core1.sfdc-cehfhs.salesforceliveagent.com/content';
	baseLiveAgentURL =
		'https://d.la11-core1.sfdc-cehfhs.salesforceliveagent.com/chat';
	orgId = '00D24000000pTWC';
	eswLiveAgentDevName = 'Chat_Support';
	deploymentId = '57208000000L0Do';
	buttonId = '57308000000L0LE';
}

export default function useSalesforceChat() {
	// Chat embed settings
	const { startTimer, stopTimer } = useTimer();

	const [loadingAvailability, setLoadingAvailability] = useState(true);
	const [agentAvailable, setAgentAvailable] = useState(false);
	const [openingEmbed, setOpeningEmbed] = useState(false);
	const [embedOpen, setEmbedOpen] = useState(false);
	const [isSalesforceLoaded, setIsSalesforceLoaded] = useState(false);

	const client = useApolloClient();

	const availabilityRef = useRef(null);
	const conversationStarted = useRef(null);
	const sessionKey = useRef(null);
	const hasTrackedRef = useRef({
		waitingInQueue: false,
		conversationStarted: false,
		messageSent: false,
		messageReceived: false,
		conversationEnded: false,
		leftQueue: false,
	});

	function trackEvent({
		event,
		conversation_id,
		waiting_duration,
		conversation_duration,
	}) {
		if (!event) return;

		client
			.mutate({
				mutation: TRACK_CHAT_EVENT,
				variables: {
					event,
					properties: {
						conversation_id: conversation_id || sessionKey.current,
						waiting_duration,
						conversation_duration,
					},
				},
			})
			.catch(error => {
				console.error(error);
				return {};
			})
			.then(({ data }) => {
				console.log(data);
			});
	}

	// Init Salesforce LiveAgent Chat
	async function initESW(gslbBaseURL) {
		const user = await getUser(client); // Get user

		let embedded_svc = window.embedded_svc || [];

		// this must be configured BEFORE init
		embedded_svc.addEventHandler(
			'onSettingsCallCompleted',
			function (data) {
				console.log(
					'onSettingsCallCompleted event was fired. Agent availability status is ' +
						data.isAgentAvailable
						? 'online'
						: 'offline'
				);
			}
		);

		embedded_svc.settings.displayHelpButton = false; //Or false
		embedded_svc.settings.language = 'no'; //For example, enter 'en' or 'en-US'

		//embedded_svc.settings.defaultMinimizedText = '...'; //(Defaults to Chat with an Expert)
		//embedded_svc.settings.disabledMinimizedText = '...'; //(Defaults to Agent Offline)

		embedded_svc.settings.loadingText = 'Laster'; //(Defaults to Loading)
		//embedded_svc.settings.storageDomain = 'yourdomain.com'; //(Sets the domain for your deployment so that visitors can navigate subdomains during a chat session)

		// Settings for Chat
		//embedded_svc.settings.directToButtonRouting = function(prechatFormData) {
		// Dynamically changes the button ID based on what the visitor enters in the pre-chat form.
		// Returns a valid button ID.
		//};
		embedded_svc.settings.prepopulatedPrechatFields = {
			FirstName: user?.first_name,
			LastName: user?.last_name,
			SuppliedEmail: user?.email,
			SuppliedPhone: user?.phone,
			Subject: user?.subject || '',
		}; //Sets the auto-population of pre-chat form fields
		//embedded_svc.settings.fallbackRouting = []; //An array of button IDs, user IDs, or userId_buttonId
		//embedded_svc.settings.offlineSupportMinimizedText = '...'; //(Defaults to Contact Us)

		embedded_svc.settings.enabledFeatures = ['LiveAgent'];
		embedded_svc.settings.entryFeature = 'LiveAgent';

		embedded_svc.init(
			initUrl,
			initUrlSetupFlow,
			gslbBaseURL,
			orgId,
			eswLiveAgentDevName,
			{
				baseLiveAgentContentURL,
				deploymentId,
				buttonId,
				baseLiveAgentURL,
				eswLiveAgentDevName,
				isOfflineSupportEnabled: false,
			}
		);

		// When user successfully fills out form and joins queue
		embedded_svc.addEventHandler('onChatRequestSuccess', function (data) {
			console.log('onChatRequestSuccess event was fired');
			startTimer();

			if (data?.liveAgentSessionKey)
				sessionKey.current = data?.liveAgentSessionKey;

			// Track chat event
			if (hasTrackedRef?.current?.waitingInQueue) return;
			trackEvent({
				event: 'Live Chat Waiting In Queue',
				conversation_id: data?.liveAgentSessionKey || undefined,
			});
			hasTrackedRef.current = {
				...hasTrackedRef?.current,
				waitingInQueue: true,
			};
		});

		// When chat established/started
		embedded_svc.addEventHandler('onChatEstablished', function (data) {
			console.log('onChatEstablished event was fired.');

			// Stop the waiting-counter
			const waiting_duration = stopTimer();

			// Start conversation-duration-timer
			startTimer();

			conversationStarted.current = true;

			if (hasTrackedRef?.current?.conversationStarted) return;
			trackEvent({
				event: 'Live Chat Conversation Started',
				conversation_id: data?.liveAgentSessionKey || undefined,
				waiting_duration,
			});
			hasTrackedRef.current = {
				...hasTrackedRef?.current,
				conversationStarted: true,
			};
		});

		// When user submits a message
		embedded_svc.addEventHandler('onChasitorMessage', function (data) {
			console.log('onChasitorMessage event was fired.', data);

			// Track chat event
			if (!hasTrackedRef?.current?.messageSent) {
				trackEvent({
					event: 'Live Chat Message Sent',
					conversation_id: data?.liveAgentSessionKey || undefined,
				});

				hasTrackedRef.current = {
					...hasTrackedRef?.current,
					messageSent: true,
				};
			}

			setTimeout(() => {
				if (hasTrackedRef?.current?.messageReceived) return;
				trackEvent({
					event: 'Live Chat Message Received',
					conversation_id: data?.liveAgentSessionKey || undefined,
				});
				hasTrackedRef.current = {
					...hasTrackedRef?.current,
					messageReceived: true,
				};
			}, 500);
		});

		// The agent ends the chat
		embedded_svc.addEventHandler('onChatEndedByAgent', function (data) {
			console.log('onChatEndedByAgent event was fired.');

			// Stop the conversation-duration-timer
			const conversation_duration = stopTimer();

			// Track chat event
			if (hasTrackedRef?.current?.conversationEnded) return;
			trackEvent({
				event: 'Live Chat Conversation Ended',
				conversation_id: data?.liveAgentSessionKey || undefined,
				conversation_duration,
			});
			hasTrackedRef.current = {
				...hasTrackedRef?.current,
				conversationEnded: true,
			};
		});

		// The chat visitor ends the chat
		embedded_svc.addEventHandler('onChatEndedByChasitor', function (data) {
			console.log('onChatEndedByChasitor event was fired.');

			// Stop the conversation-duration-timer
			const conversation_duration = stopTimer();

			// Track chat event
			if (hasTrackedRef?.current?.conversationEnded) return;
			trackEvent({
				event: 'Live Chat Conversation Ended',
				conversation_id: data?.liveAgentSessionKey || undefined,
				conversation_duration,
			});
			hasTrackedRef.current = {
				...hasTrackedRef?.current,
				conversationEnded: true,
			};
		});

		// When chat has ended and the application is closed
		embedded_svc.addEventHandler('afterDestroy', function () {
			console.log('afterDestroy event was fired.');

			setEmbedOpen(false);

			// Stop the waiting-duration-timer
			const waiting_duration = stopTimer();

			// Track chat event if left queue without starting chat
			if (
				!conversationStarted.current &&
				!hasTrackedRef?.current?.leftQueue
			) {
				trackEvent({
					event: 'Live Chat Left Queue',
					conversation_id: sessionKey.current,
					waiting_duration,
				});
				hasTrackedRef.current = {
					...hasTrackedRef?.current,
					leftQueue: true,
				};
			}
		});
	}

	// Get agent availability
	async function getAvailabiltyStatus() {
		client
			.query({
				query: GET_CHAT_AVAILABILITY_STATUS,
				variables: {
					baseLiveAgentURL,
					orgId,
					deploymentId,
					availabilityIds: [buttonId],
				},
			})
			.catch(error => {
				console.error(error);
				setLoadingAvailability(false);
				return false;
			})
			.then(({ data }) => {
				console.log(
					'Salesforce chat agent online: ' +
						(data?.getChatAvailabiltyStatus?.isAvailable || 'false')
				);

				if (data?.getChatAvailabiltyStatus?.isAvailable !== undefined) {
					if (data?.getChatAvailabiltyStatus?.isAvailable === true) {
						setLoadingAvailability(false);
						setAgentAvailable(true);
					} else {
						setLoadingAvailability(false);
						setAgentAvailable(false);
					}
				}

				if (data?.getChatAvailabiltyStatus === null) {
					console.log(data);
					setLoadingAvailability(false);
					setAgentAvailable(false);
				}
				return true;
			});
	}

	const triggerChatOpen = (preChat = true, props) => {
		if (isSalesforceLoaded && window?.embedded_svc) {
			if (preChat) {
				window.embedded_svc.bootstrapEmbeddedService();
			} else {
				window.embedded_svc.liveAgentAPI.startChat(props);
			}
		} else {
			console.error('Salesforce Live Agent script not loaded');
		}
	};

	useEffect(() => {
		// Get chat availability
		getAvailabiltyStatus();

		// Run funtion to get status each 2 minute
		availabilityRef.current = setInterval(
			getAvailabiltyStatus,
			2 * 60 * 1000
		);

		// Init Salesforce script
		if (!window.embedded_svc) {
			var s = document.createElement('script');
			s.setAttribute('src', salesforceScript);
			s.onload = function () {
				initESW(null);
			};
			document.body.appendChild(s);
		} else {
			initESW('https://service.force.com', false);
		}

		// Clear interval
		return () => {
			if (availabilityRef.current) {
				clearInterval(availabilityRef.current);
			}
		};

		//eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (isSalesforceLoaded) return;
		// Check if Salesforce script is loaded every three second until finding it
		const intervalId = setInterval(() => {
			if (window?.embedded_svc) {
				setIsSalesforceLoaded(true);
				clearInterval(intervalId);
			}
		}, 3000);

		// Clean up the interval on unmount
		return () => clearInterval(intervalId);
		//eslint-disable-next-line
	}, []);

	return {
		agentAvailable,
		loadingAvailability,
		openingEmbed,
		setOpeningEmbed,
		embedOpen,
		setEmbedOpen,
		triggerChatOpen,
		isSalesforceLoaded,
	};
}
