import React from 'react';
import styled from 'styled-components';
import { fadeIn } from 'libs/animations';

import LoadingAnimation from 'images/icons/loading-dots-animation.inline.svg';
import ScreenReaderText from 'components/screen-reader-text';

const LoadingWrapper = styled.div`
	margin-top: 5px;
	animation-name: ${fadeIn};
	animation-duration: 1s;
	animation-fill-mode: forwards;
	svg {
		display: block;
		margin: 0 auto;
	}
`;
//#endregion

function Loading() {
	return (
		<LoadingWrapper>
			<LoadingAnimation />
			<ScreenReaderText>Laster chat status</ScreenReaderText>
		</LoadingWrapper>
	);
}

export default Loading;
